import React from 'react'
import { P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { RoleSection, RoleTemplate, RoleTitle, RoleUl } from '../RoleTemplate'

const WillWriter = (): React.JSX.Element => (
  <RoleTemplate
    roleName="Will Writer"
    roleUrl="https://dignity.kallidusrecruit.com/VacancyInformation.aspx?VId=2183"
    location="London, Hybrid (3 days on-site)"
    salary="£30,000 (Junior), £33,600 - £36,000 (Senior depending on experience)"
    contractType="Full-time, Permanent"
    closingDate="30th April 2025"
    content={
      <>
        <RoleSection>
          <RoleTitle>The Role</RoleTitle>
          <P>
            At Farewill, now a part of the Dignity Funerals Group, we help
            people navigate some of life’s most challenging moments with
            kindness, expertise, and care. We’re on a mission to make
            will-writing simpler, more affordable, and accessible to everyone.
          </P>
          <P>
            We are looking for brilliant people to join our Wills Team. You will
            be responsible for providing the best possible experience to our
            customers.
          </P>
          <P>
            At Farewill we provide a variety of wills. Over the phone, online,
            simple and complex. This will mean you are doing a variety of tasks
            for customers with a variety of will needs. This includes speaking
            to customers who are deciding whether to use or service, drafting
            wills that have been conducted over the phone and supporting with
            any follow up questions. Occasionally, you will be called upon to
            support other areas of the legal services function.
          </P>
          <P>
            You will have a great telephone manner and knowledge to provide the
            best experience to our thousands of wills customers.
          </P>
          <P>
            Whether you’re an experienced will writer or come from a legal
            background but looking to start out in the will writing world, this
            is an opportunity to make a real difference.
          </P>
        </RoleSection>
        <RoleSection margin={[GTR.M, 0]}>
          <RoleTitle>What the day-to-day will look like for you:</RoleTitle>
          <RoleUl>
            <li>
              Guide customers through the will-writing process over the phone
              and email, providing professional and empathetic support.
            </li>
            <li>
              Use WillSuite to draft clear and legally sound wills tailored to
              individual needs.
            </li>
            <li>
              Conduct fact find calls to understand customers wishes and needs
              for their will
            </li>
            <li>
              Draft brilliant wills that are factually and legally correct and
              free from errors
            </li>
            <li>
              Deliver against key KPI’s on quality as well as quantity of work
            </li>
            <li>
              Ensure all cases are closed within five working days once
              customers confirm their understanding and satisfaction
            </li>
            <li>
              Respond to customer queries in a timely manner via phone or email,
              ensuring prompt and accurate communication
            </li>
            <li>
              Escalate complex cases or complaints where necessary to uphold
              service standards.
            </li>
            <li>
              Work as part of a small, dynamic team, adapting to business needs
              as required.
            </li>
            <li>
              Senior Wills Specialists will support and mentor junior team
              members to help develop their skills.
            </li>
          </RoleUl>
        </RoleSection>
        <RoleSection>
          <RoleTitle>We are looking for someone who has:</RoleTitle>
          <RoleUl>
            <li>
              Passion for supporting customers and charities, eager to join a
              dynamic environment and make a meaningful impact
            </li>
            <li>
              Junior roles: prior experience with legal administration or higher
              education qualifications relevant to the field (e.g. paralegal, or
              a law degree)
            </li>
            <li>
              Senior roles: Multiple years’ experience drafting wills or
              relevant professional qualification (e.g. Cilex, or STEP
              qualification)
            </li>
            <li>
              Great phone manner and written communication with customers, with
              the ability to explain legal concepts clearly and empathetically
            </li>
            <li>
              Meticulous attention to detail to ensure legal accuracy in all
              documentation
            </li>
            <li>
              A customer-first mindset, offering support through sensitive
              conversations with professionalism and care
            </li>
            <li>
              Brilliant organisational skills and the ability to manage multiple
              customers
            </li>
            <li>Hunger to learn and continue to develop knowledge</li>
          </RoleUl>
        </RoleSection>
        <RoleSection>
          <RoleTitle>What we offer</RoleTitle>
          <RoleUl>
            <li>Annual salary of £30,000 - £36,000 depending on experience</li>
            <li>25 Days Holiday + Bank Holidays</li>
            <li>x2 Life Assurance</li>
            <li>Pension Scheme</li>
            <li>Hybrid flexible working (3 days in office)</li>
            <li>Access to our internal colleague development schemes</li>
          </RoleUl>
        </RoleSection>
      </>
    }
  />
)

export default WillWriter
